import React, { FC } from 'react'

import Web3ReactWrapper from './Web3ReactWrapper'

const LazyWeb3ReactProvider: FC = ({ children }) => {
  // const { hasProviderMenuBeenOpened } = useSelector((state: State) => state.ui)

  /* if (hasProviderMenuBeenOpened) {
    return <Web3ReactWrapper>{children}</Web3ReactWrapper>
  } else {
    return <>{children}</>
  }*/
  return <Web3ReactWrapper>{children}</Web3ReactWrapper>
}

export default LazyWeb3ReactProvider
