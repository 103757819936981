import { ReactComponent as Infinity } from 'app-images/animation/infinity.svg'
import React from 'react'

import { TradeType } from '../../../model'

export interface ButtonContentProps {
  errorMsg?: string
  type?: TradeType
  txnVerificationComplete?: boolean
  isHovered?: boolean
  currentState?: string
  txnVerificationInProgress?: boolean
}

export const ButtonContentDesktop = ({
  errorMsg,
  type,
  txnVerificationComplete,
  isHovered,
  currentState,
  txnVerificationInProgress,
}: ButtonContentProps): React.ReactElement => {
  const printButtonText = (): React.ReactElement => {
    if (errorMsg) {
      return <span className="caption">{errorMsg}</span>
    }

    if (txnVerificationInProgress && !txnVerificationComplete) {
      return (
        <div className="tradeform-button__content tradeform-button__content--pending">
          <div className="caption">
            <span>TX Pending</span>
          </div>
          <div className="progress">
            <Infinity className="indicator" />
          </div>
        </div>
      )
    }

    if ((currentState === 'NOT_APPROVED' && isHovered) || currentState === 'APPROVE_IN_PROGRESS') {
      return (
        <div className="tradeform-button__content tradeform-button__content--approve">
          <div className="caption">
            <span className="step">1</span>
            <span>Approve</span>
          </div>
          <div className="progress">
            <Infinity className="indicator" />
          </div>
          <div className="caption">
            <span className="step">2</span>
            <span data-testid="second-step">{type}</span>
          </div>
        </div>
      )
    }

    return <span className="caption">{type}</span>
  }

  return printButtonText()
}
