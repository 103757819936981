import React, { FC, Suspense } from 'react'
import Web3 from 'web3'
import { provider } from 'web3-core'

function getLibrary(provider: provider): Web3 {
  return new Web3(provider)
}

const Web3ReactProvider = React.lazy(
  () => import(/* webpackPrefetch: true */ './Web3ReactProvider')
)

const Web3ReactWrapper: FC = ({ children }) => (
  <Suspense fallback={<></>}>
    <Web3ReactProvider getLibrary={getLibrary}>{children}</Web3ReactProvider>
  </Suspense>
)

export default Web3ReactWrapper
