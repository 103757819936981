import cn from 'classnames'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import getTestId from '../../helpers/getTestId'

export type CloseButtonProps = {
  size?: 'md' | 'sm'
  to?: string
  className?: string
  testId?: string
  onClick?: () => void
  tooltip?: string
}

/* FIXME:
use component={<Link/>} prop, and whitelisted params (to)

<CloseButton to="/token/...-eth" component={<Link/>} />
and if component prop were not passed use <button .../>

*/

const CloseButton: FC<CloseButtonProps> = ({
  size = 'md',
  to,
  className,
  testId,
  onClick,
  tooltip,
}) => {
  if (to) {
    return (
      <Link
        to={to}
        className={cn(
          'close-button',
          { [`close-button--${size}`]: !!size },
          { [`${className}`]: !!className }
        )}
        onClick={onClick}
        data-testid={getTestId('close-btn', testId)}
        data-tip={tooltip}
        data-for={tooltip ? 'app-tooltip' : undefined}
      />
    )
  }

  return (
    <button
      className={cn(
        'close-button',
        { [`close-button--${size}`]: !!size },
        { [`${className}`]: !!className }
      )}
      onClick={onClick}
      data-testid={getTestId('close-btn', testId)}
      data-tip={tooltip}
      data-for={tooltip ? 'app-tooltip' : undefined}
    />
  )
}

export default CloseButton
