import classNames from 'classnames'
import React, { FC } from 'react'

import { TokenWithApprovalContext } from '../../model'
import { getInlineNetworkIconStyle, getMarketDisplayName, getNetworkConfig } from '../../utils'
import IconTokenWrapper from '../IconTokenWrapper'

interface ButtonTokenProps {
  token?: TokenWithApprovalContext
  onClick: (event: React.MouseEvent) => void
}

const ButtonToken: FC<ButtonTokenProps> = ({ token, onClick }: ButtonTokenProps) => {
  if (!token) {
    return null
  }
  const networkConfig = getNetworkConfig(token.network)
  const styleIcon = getInlineNetworkIconStyle(token?.network, networkConfig)

  return (
    <button
      className="button button--md tradeform-token"
      data-id={`${token?.id}`}
      onClick={onClick}>
      <div
        className={classNames(
          'tradeform-token__icon',
          'token-ico',
          'token-ico--sm',
          token?.network ? `token-ico--network-${token.network}` : ''
        )}
        style={styleIcon}>
        <IconTokenWrapper
          logoURI={token?.logoURI || ['']}
          symbols={token?.symbols || ['']}
          className="token-ico__image"
        />
      </div>
      <span className="tradeform-token__name" title={getMarketDisplayName(token)}>
        <span className="caption">{getMarketDisplayName(token)}</span>
      </span>
    </button>
  )
}

export default ButtonToken
