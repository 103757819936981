import React from 'react'

interface TapToApproveButtonProps {
  tradeType: string
  disabled: boolean
  onClick: (e: React.MouseEvent) => void
  text?: string
}

const TapToApproveButton = ({
  tradeType,
  disabled,
  onClick,
  text,
}: TapToApproveButtonProps): React.ReactElement => (
  <button
    className={`button button--xl tradeform-button tradeform-button--${tradeType.toLowerCase()} negative`}
    disabled={disabled}
    onClick={onClick}>
    <span className="caption">{text || 'Tap to Approve'}</span>
  </button>
)

export default TapToApproveButton
