import { ReactComponent as Infinity } from 'app-images/animation/infinity.svg'
import classNames from 'classnames'
import React, { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import getTestId from '../../helpers/getTestId'
export type ButtonSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs'

export enum BtnSizes {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
}

export enum BtnVariants {
  main = 'main',
  secondary = 'secondary',
}

export interface ButtonProps {
  caption?: string
  indicator?: string
  icon?: FC | ReactElement
  buttonSize?: ButtonSize
  className?: string
  active?: boolean
  disabled?: boolean
  pending?: boolean
  href?: string
  to?: string
  testId?: string
  variant?: BtnVariants
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const Button: FC<React.HTMLProps<HTMLElement> & ButtonProps> = ({
  caption,
  indicator,
  icon,
  buttonSize,
  className,
  active,
  disabled,
  pending,
  href,
  to,
  testId,
  onClick,
  children,
  variant,
  ...rest
}) => {
  const classProp = classNames(
    'button',
    {
      [`button--${buttonSize}`]: !!buttonSize,
      active: !!active,
      disabled: !to && !href && disabled,
      pending: pending,
      [`button--${variant}`]: !!variant,
    },
    className
  )

  const content = (
    <>
      {!!icon && (
        <span className="button__icon">
          <span className="icon">{icon}</span>
        </span>
      )}
      {(caption || children) && <span className="button__caption">{caption || children}</span>}
      {pending && <Infinity className="button__pending" />}
      {indicator && <span className="button__indicator">{indicator}</span>}
    </>
  )
  if (to) {
    return (
      <Link
        to={to}
        className={classProp}
        data-testid={getTestId('button', testId)}
        onClick={onClick}>
        {content}
      </Link>
    )
  }

  return React.createElement(
    href ? 'a' : 'button',
    {
      className: classProp,
      'data-testid': getTestId('button', testId),
      disabled: disabled,
      href: href,
      onClick,
      ...rest,
    },
    content
  )
}

export default Button
