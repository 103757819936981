import { ScanIcon } from '@telekomconsalting/react-dexguru-wallet'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { QUOTE_REFRESHABLE_INTERVAL } from '../../config/settings'
import {
  StatefulTransaction,
  TokenSpenders,
  TokenWithApprovalContext,
  TradeType,
} from '../../model'
import { State } from '../../reducers'
import { getNetworkConfig, getNetworkDescriptionName } from '../../utils'
import { desktopFormStates, FormState, FormStateTypes } from '../Form/formDesktopResources'
import { useTokens } from '../Form/hooks'
import SwitchNetwork from '../SwitchNetwork'
import { ButtonContentDesktop } from './ButtonContent/ButtonContentDesktop'
import TapButton from './TapToApproveButton'

interface FormButtonDesktopProps {
  spender: TokenSpenders
  currentState: string
  errorMsg?: string
  tradeType: TradeType
  fromAmount: BigNumber
  onVerify: () => void
  onRefreshQuote: () => void
  onApprove: (e: React.MouseEvent) => void
}

const FormButtonDesktop: React.FC<FormButtonDesktopProps> = ({
  spender,
  errorMsg,
  tradeType,
  fromAmount,
  onVerify,
  onApprove,
  onRefreshQuote,
  currentState,
}) => {
  const txPendingTimer = useRef<NodeJS.Timeout>()
  const { tokenFrom } = useTokens(tradeType)
  const txn: StatefulTransaction = useSelector((state: State) => state.txn[tradeType])
  const currentToken: TokenWithApprovalContext | undefined = useSelector(
    (state: State) => state.tokens.currentToken
  )

  const [isHovered, setIsHovered] = useState(false)

  const [stateMessage, setStateMessage] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [isShowRefreshQuote, setShowRefreshQuote] = useState(false)

  useEffect(() => {
    if (txn.estimateLoading) {
      setIsDisabled(true)
    }
    if (currentState === FormStateTypes.INSUFFICIENT_BALANCE) {
      setIsDisabled(true)
      const state = desktopFormStates[currentState] as FormState
      return setStateMessage(state.error)
    }

    if (currentState === FormStateTypes.WRONG_NETWORK_DESKTOP) {
      setIsDisabled(true)
      return setStateMessage(
        `Currently not connected to ${getNetworkDescriptionName(currentToken?.network)}`
      )
    }

    if (currentState === FormStateTypes.ERROR) {
      setIsDisabled(true)
      return setStateMessage('')
    }

    setIsDisabled(false)
    return setStateMessage('')
  }, [currentState, currentToken?.network, txn.estimateLoading])

  useEffect(() => {
    !isShowRefreshQuote && txPendingTimer?.current && clearTimeout(txPendingTimer.current)

    if (
      ![FormStateTypes.OK, FormStateTypes.NOT_APPROVED].includes(currentState as FormStateTypes)
    ) {
      isShowRefreshQuote && setShowRefreshQuote(false)
      return
    }

    if (
      (currentState === FormStateTypes.OK || currentState === FormStateTypes.NOT_APPROVED) &&
      fromAmount.lte(0)
    ) {
      isShowRefreshQuote && setShowRefreshQuote(false)
      return
    }

    if (!isShowRefreshQuote) {
      txPendingTimer.current = setTimeout(function tick() {
        if (fromAmount.gt(0)) {
          setShowRefreshQuote(true)
          onRefreshQuote()
        }
      }, QUOTE_REFRESHABLE_INTERVAL)
    }

    return (): void => {
      txPendingTimer.current && clearTimeout(txPendingTimer.current)
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21246/chore-fix-warnings-in-trading-form-components-15
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromAmount, currentState, txn.estimatedAmount, isShowRefreshQuote])

  const onClick = (e: React.MouseEvent): void => {
    if (tokenFrom[spender]?.isTokenApproved) {
      return onVerify()
    }

    return onApprove(e)
  }

  if (!currentToken?.id) {
    return null
  }

  let networkConfig
  if (currentToken.network) {
    networkConfig = getNetworkConfig(currentToken.network)
  }

  return (
    <div className="tradeform-proceed">
      {isShowRefreshQuote && (
        <TapButton
          tradeType={tradeType}
          disabled={isDisabled}
          onClick={(): void => {
            setShowRefreshQuote(false)
            onRefreshQuote()
          }}
          text="Click to Refresh Quote"
        />
      )}
      {!isShowRefreshQuote && (
        <button
          className={classNames(
            `button button--xl tradeform-button tradeform-button--${tradeType.toLowerCase()}`,
            {
              loading: Boolean(txn.txnVerificationInProgress || txn.approvalInProgress),
            }
          )}
          disabled={isDisabled}
          onMouseEnter={(): void => {
            setIsHovered(true)
          }}
          onMouseLeave={(): void => {
            setIsHovered(false)
          }}
          onClick={onClick}>
          <ButtonContentDesktop
            errorMsg={stateMessage}
            type={tradeType}
            txnVerificationComplete={txn.txnVerificationComplete}
            txnVerificationInProgress={txn.txnVerificationInProgress}
            isHovered={isHovered}
            currentState={currentState}
          />
        </button>
      )}
      {currentState === FormStateTypes.WRONG_NETWORK_DESKTOP && (
        <SwitchNetwork errorMsg={errorMsg || `Wrong Network`} network={currentToken.network} />
      )}
      <div
        className={classNames('tradeform-proceed__message', {
          'tradeform-proceed__message--disabled': !(txn.hashTxn && txn.isLoading),
        })}>
        {txn.hashTxn && networkConfig && (
          <a
            className="explorer"
            href={`${networkConfig.block_explorer.url}/tx/${txn.hashTxn}`}
            target="_blank"
            rel="noopener noreferrer">
            <ScanIcon networkConfig={networkConfig} className="explorer__icon" />
            <span className="explorer__caption">
              View on {networkConfig.block_explorer.display_name}
            </span>
          </a>
        )}
      </div>
      {!txn.hashTxn &&
        (currentState === FormStateTypes.NOT_APPROVED ||
          currentState === FormStateTypes.APPROVE_IN_PROGRESS) && (
          <div
            className={classNames('tradeform-proceed__message', {
              'tradeform-proceed__message--disabled': !isHovered,
            })}>
            <div className="text">You'll need to approve token spending first</div>
          </div>
        )}
    </div>
  )
}

export default FormButtonDesktop
